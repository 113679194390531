import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../scss/page.scss"

const ResourcesPage = () => (
  <Layout>
    <article className="Page pad">
      <SEO title="Resources" />
      <h2>More Resources</h2>
      <p>Choose who you are so we help you in this time of crisis and fear.</p>

      <ul>
        <li>
          <p>If you’re a friend</p>
          <span><a href="https://seizetheawkward.org/" target="_blank" rel="noopener noreferrer" title="">Seize the Awkward</a></span>
        </li>
        <li>
          <p>If you're a parent</p>
          <span><a href="https://childmind.org/coping-during-covid-19-resources-for-parents/" target="_blank" rel="noopener noreferrer" title="">Child Mind Institute</a>: Supporting Families during COVID-19</span>
        </li>
        <li>
          <p>If you're a student</p>
          <span><a href="https://www.activeminds.org/blog/coping-and-staying-emotionally-well-during-covid-19-related-school-closures/" target="_blank" rel="noopener noreferrer" title="">Active Minds</a>: Coping and staying emotionally well during COVID-19 related school closures</span>
        </li>
      </ul>
    </article>
  </Layout>
)

export default ResourcesPage
